<template>
  <div>
    <vx-card class="vx-card mb-base">
       <div class="flex items-center justify-between">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex items-center">
                <a @click="$router.go(-1)" style="cursor:pointer">
                  Manage
                </a>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex items-center">
                <router-link
                  :to="{
                    name: 'director-room-list',
                    params: { id: centerId }
                  }"
                  >Rooms
                </router-link>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>

              <li class="inline-flex items-center">
                <a href="javascript:void(0)" class>Add New Room</a>
              </li>
            </ul>
          </div>
        </div>

      <!-- <h3>Add new room</h3> -->
      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" ws-sm="12" vs-lg="4" vs-xs="12">
          <div class="w-full mb-5">
            <template v-if="dataImg">
              <vs-avatar :src="dataImg" class="add-room-image"/>

              <!-- Image upload Buttons -->
              <div class="modify-img flex my-5">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button href="#" @click="$refs.updateImgInput.click()">Update Image</vs-button>
                <vs-button
                  color="danger"
                  class="ml-4"
                  @click="dataImg = null; image = null"
                >Remove Image
                </vs-button>
              </div>
            </template>

            <div class="upload-img mt-5" v-if="!dataImg">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button type="border" class="btn-blue-border" @click="$refs.uploadImgInput.click()">Upload Photo
              </vs-button>
            </div>
            <span class="text-danger text-sm" v-show="customError != ''">{{ customError }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-xs="12">
          <div class="w-full mb-5">
            <vs-input
              v-validate="'required'"
              name="name"
              class="w-full"
              v-model="name"
              label-placeholder="Room Name"
            />
            <span class="text-danger text-sm">{{ errors.first("name") }}</span>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-xs="12">
          <div class="w-full mb-5">
            <vs-input
              v-validate="'required'"
              name="location"
              class="w-full"
              v-model="location"
              label-placeholder="Room Location"
            />
            <span class="text-danger text-sm">{{ errors.first("location") }}</span>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-xs="12">
          <div class="w-full mb-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <label class="block mb-2">Age Group</label>
                <vs-select
                  placeholder="Age Group"
                  autocomplete
                  v-model="ageGroup"
                  class="w-full"
                  v-validate="'required'"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item,index) in ageGroupOptions"
                    :clearable="false"
                  />
                </vs-select>
              </div>
            </div>
            <span class="text-danger text-sm">{{ errors.first("ageGroup") }}</span>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

<!--    <div class="flex flex-wrap">-->
<!--      <div class="w-full lg:w-1/2 mb-base">-->
<!--        <vx-card class="mb-base room-details-table">-->
<!--          <div class="w-full mb-5">-->
<!--            <vs-table :data="selectedTeachers">-->
<!--              <template slot="header">-->
<!--                <div class="flex w-full justify-between mb-8">-->
<!--                  <div class="w-1/2">-->
<!--                    <h2 class="mb-0">Teachers</h2>-->
<!--                  </div>-->
<!--                  <div class="w-1/2 text-right">-->
<!--                    <span class="mr-2 feather-icon select-none relative">-->
<!--                      <a href="javascript:void(0)" @click="teacherPopup=true">Add/edit</a>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template slot="thead">-->
<!--                <vs-th>Name</vs-th>-->
<!--              </template>-->

<!--              <template slot-scope="{data}">-->
<!--                <vs-tr v-for="(option, key) in selectedTeachers" :key="key">-->
<!--                  <vs-td>-->
<!--                    <div class="flex items-center">-->
<!--                      <vs-avatar size="50px" :src="option.photo" class="mr-6 ml-0"/>-->
<!--                      {{ option.label }}-->
<!--                    </div>-->
<!--                  </vs-td>-->
<!--                </vs-tr>-->
<!--              </template>-->
<!--            </vs-table>-->
<!--          </div>-->
<!--        </vx-card>-->
<!--      </div>-->
<!--      <div class="w-full lg:w-1/2 mb-base">-->
<!--        <vx-card class="mb-base room-details-table">-->
<!--          <div class="w-full mb-5">-->
<!--            <vs-table :data="selectedChildren">-->
<!--              <template slot="header">-->
<!--                <div class="flex w-full justify-between mb-8">-->
<!--                  <div class="w-1/2">-->
<!--                    <h2 class="mb-0">Childrens</h2>-->
<!--                  </div>-->
<!--                  <div class="w-1/2 text-right">-->
<!--                    <span class="mr-2 feather-icon select-none relative">-->
<!--                      <a href="javascript:void(0)" @click="childPopup=true">Add/ edit</a>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template slot="thead">-->
<!--                <vs-th>Name</vs-th>-->
<!--              </template>-->

<!--              <template slot-scope="{data}">-->
<!--                <vs-tr v-for="(option, key) in selectedChildren" :key="key">-->
<!--                  <vs-td>-->
<!--                    <div class="flex items-center">-->
<!--                      <vs-avatar size="50px" :src="option.photo" class="mr-6 ml-0"/>-->
<!--                      {{ option.label }}-->
<!--                    </div>-->
<!--                  </vs-td>-->
<!--                </vs-tr>-->
<!--              </template>-->
<!--            </vs-table>-->
<!--          </div>-->
<!--        </vx-card>-->
<!--      </div>-->
<!--    </div>-->

    <div class="vx-row mx-5">
      <div class="vx-col w-full mb-base text-right">
        <vs-button @click="createRoom" color="primary">Save</vs-button>
      </div>
    </div>

    <vs-popup class="holamundo add-children-popup" title :active.sync="teacherPopup">
      <form class="p-6 pt-0">
        <h2 class="h1 mb-6">Add Teacher</h2>
        <vx-card class="m-0 my-8">
          <vs-row>
            <vs-col vs-w="12">
              <ul v-if="teachersOptions.length > 0">
                <li class="my-2">
                  <vs-checkbox
                    :vs-id="'checkbox-a'"
                    @click="selectAllTeachers(teachersToggle)"
                  >Select All
                  </vs-checkbox>

                </li>
                <li v-for="(option, key) in teachersOptions" :key="key" class="my-2">
                  <vs-checkbox
                    :vs-id="'checkbox-'+key"
                    name="selectedTeacher"
                    v-model="selectedTeachers"
                    :vs-value="option"
                  >
                    <div class="flex items-center">
                      <vs-avatar size="30px" :src="option.photo" class="mr-6"/>
                      <span>{{ option.label }}</span>
                    </div>
                  </vs-checkbox>
                </li>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selectedTeacher')"
                >{{ errors.first('selectedTeacher') }}</span>
              </ul>
              <p v-else>No teacher found</p>
            </vs-col>
          </vs-row>

          <span
            class="text-danger text-sm"
            v-show="errors.has('children')"
          >{{ errors.first('children') }}</span>
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button @click="cancelAddTeacher" color="danger" type="filled">Cancel</vs-button>
          <vs-button @click="addTeacherToRoom" color="primary" type="filled">Add Teacher</vs-button>
        </div>
      </form>
    </vs-popup>
    <vs-popup class="holamundo add-children-popup" title :active.sync="childPopup">
      <form class="p-6 pt-0">
        <h2 class="h1 mb-6">Add Children</h2>
        <vx-card class="m-0 my-8">
          <!--<vs-row>
                        <vs-col vs-w="12">
                            <vx-input-group :class="'mb-base ' + searchText ? 'hasValue':''">
                                <vs-input icon-no-border icon="search" label-placeholder="Search" v-model="searchText"/>
                            </vx-input-group>
                        </vs-col>
          </vs-row>-->
          <vs-row>
            <vs-col vs-w="12">
              <ul v-if="childrenOptions.length > 0">
                <li>
                  <vs-checkbox
                    :id="'checkbox-a'"
                    @click="selectAllChildren(childrenToggle)"
                  >Select All
                  </vs-checkbox>
                  <!-- <div class="round" style="display: flex; align-items: center">
                    <input
                      :id="'checkbox-a'"
                      type="checkbox"
                      @click="selectAllChildren(childrenToggle)"
                    />
                    <label :for="'checkbox-a'">
                      <i class="feather icon-check"></i>
                    </label>
                    <span style="margin-left: 23px; font-size: 16px; line-height: 22px;">Select All</span>
                  </div>-->
                </li>
                <li v-for="(option, key) in childrenOptions" :key="key">
                  <vs-checkbox
                    :id="'checkbox-'+key"
                    name="selectedChildren"
                    v-model="selectedChildren"
                    :vs-value="option"
                  >
                    <div class="flex items-center">
                      <vs-avatar size="30px" :src="option.photo" class="mr-6"/>
                      <span>{{ option.label }}</span>
                    </div>
                  </vs-checkbox>
                </li>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selectedChildren')"
                >{{ errors.first('selectedChildren') }}</span>
              </ul>
              <p v-else>No Child found</p>
            </vs-col>
          </vs-row>

          <span
            class="text-danger text-sm"
            v-show="errors.has('children')"
          >{{ errors.first('children') }}</span>
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button @click="cancelAddChildren" color="danger" type="filled">Cancel</vs-button>
          <vs-button @click="addChildrenToRoom" color="primary" type="filled">Add Children</vs-button>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  data() {
    return {
      centerId: this.$route.params.centerId,
      ageGroupOptions: [],

      name: "",
      location: "",
      ageGroup: "",
      dataImg: "",
      image: null,
      customError: "",

      childPopup: false,
      teacherPopup: false,

      childrenOptions: [],
      teachersOptions: [],
      selectedTeachers: [],
      selectedChildren: [],
      childrenToggle: true,
      teachersToggle: true,
    };
  },
  methods: {
    ...mapActions("room", ["getAgeGroups", "saveRoom"]),
    ...mapActions("center", [
      "getFreeChildrenOfCenter",
      "getFreeTeacherOfCenter",
    ]),

    async updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },

    async createRoom() {
      this.$validator.validateAll().then( async (result) => {
        if (result) {
          if (!this.dataImg) {
            this.customError = "Logo image required";
            return false;
          }

          let teachersIds = _.map(this.selectedTeachers, "value");
          let childrenIds = _.map(this.selectedChildren, "value");

          let data = new FormData();

          if (teachersIds.length > 0) {
            for (var i = 0; i < teachersIds.length; i++) {
              data.append("teachers[]", teachersIds[i]);
            }
          }

          if (childrenIds.length > 0) {
            for (var n = 0; n < childrenIds.length; n++) {
              data.append("children[]", childrenIds[n]);
            }
          }
          data.append("learningCenterId", this.centerId);
          data.append("name", this.name);
          data.append("image", this.image);
          data.append("location", this.location);
          data.append("ageGroup", this.ageGroup);
          data.append("createdBy", this.$store.state.AppActiveUser.id);

          await this.saveRoom(data)
            .then((response) => {
              this.$vs.notify({
                title: "Success",
                text: "Room created successfully",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push({
                name: "director-room-list",
                params: {id: this.centerId},
              });
            })
            .catch((err) => {
              if (err.response.status === 422) {
                this.errors.add({
                  field: "name",
                  msg: err.response.data.message,
                });

                this.$vs.notify({
                  title: "Error",
                  text: "Please fix the errors",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: "some thing went wrong",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
              }
            });
        }
      });
    },

    async fetchChildrenOfCenter() {
      this.getFreeChildrenOfCenter(this.centerId).then((response) => {
        let self = this;
        const children = response.data.data;

        self.childrenOptions = children.map((item) => {
          return {
            label: item.fullName,
            value: item._id,
            photo: item.photo,
          };
        });
      });
    },

    async fetchTeachersOfCenter() {
      this.getFreeTeacherOfCenter(this.centerId).then((response) => {
        let self = this;
        const teachers = response.data.data;

        self.teachersOptions = teachers.map((item) => {
          return {
            label: item.fullName,
            value: item._id,
            photo: item.photo,
          };
        });
      });

    },
    cancelAddChildren() {
      this.selectedChildren = [];
      this.childPopup = false;
    },
    cancelAddTeacher() {
      this.selectedTeachers = [];
      this.teacherPopup = false;
    },

    addTeacherToRoom() {
      console.log(this.selectedTeachers, "teacher");
      this.teacherPopup = false;
    },

    addChildrenToRoom() {
      console.log(this.selectedChildren, 'child"');
    },

    selectAllTeachers(res) {
      this.teachersToggle = !res;
      if (res) {
        this.selectedTeachers = this.teachersOptions;
      } else {
        this.selectedTeachers = [];
      }
    },
    selectAllChildren(res) {
      this.childrenToggle = !res;
      if (res) {
        this.selectedChildren = this.childrenOptions;
      } else {
        this.selectedChildren = [];
      }
    },
    fetchAgeGroups() {
      this.getAgeGroups().then((response) => {
        this.ageGroupOptions = this.$store.state.room.ageGroups;
      });
    },
  },
  created() {
    this.fetchAgeGroups();
    this.fetchChildrenOfCenter();
    this.fetchTeachersOfCenter();
  },
};
</script>
<style scoped lang="scss">
.add-room-image {
  width: 123px;
  height: 123px;
  margin: 0;
  margin-top: 20px;
}
</style>
